import API from "../api";

export const login = async (formData) => { 
    try {
        const response = await API.post("/api/v1/user/login", formData);

        if (!response.data.status) {
            throw new Error(response.data.message);
        } else {
            return response.data;
        }
    } catch (error) {
        console.error("Error en la autenticación:", error);
        throw error;
    }
};
