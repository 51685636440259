import React, { createContext, useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { login as loginAction } from '../actions/auth';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(() => JSON.parse(localStorage.getItem('profile')));
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const navigate = useNavigate();

  const login = async (formData) => {
    try {
      setLoading(true);
      const response = await loginAction(formData);
      setAuthData(response);
      localStorage.setItem('profile', JSON.stringify(response));
      setErrors(null);
      navigate('/');
      setLoading(false);
    } catch (error) {
      setErrors(error.message);
    }
  };

  const logout = () => {
    setAuthData(null);
    localStorage.removeItem('profile');
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ authData, login, logout, loading, errors, isAuthenticated: !!authData }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);