import React, { useState } from "react";
import { Input } from "../components";
import { useAuth } from "../contexts/AuthProvider"; 

const initialState = { name: '', password: '' }

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const { login, loading, errors } = useAuth();


  const handleShowPassword = () => setShowPassword(!showPassword);

  const handleSubmit = (e) => {
    e.preventDefault();
    login(formData); 
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 dark:bg-gray-800">
      <div className="bg-white dark:bg-gray-900 p-8 rounded-lg shadow-lg max-w-md w-full">
      <img src="/images/topdentist-logo.webp" alt="TopDentist Logo" className="w-32 mx-auto mb-4" />

        {errors && (
          <div className="text-red-500 text-sm text-center">
            {errors}
          </div>
        )}

        <form className="mx-auto mt-2 max-w-xl" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-2">
            <div className="sm:col-span-2">
              <Input
                name={"name"}
                type={"name"}
                label={"Nombre"}
                handleChange={handleChange}
              />
            </div>
            <div className="sm:col-span-2">
              <Input
                name={"password"}
                type={showPassword ? 'text' : 'password'}
                label={"Contraseña"}
                handleChange={handleChange}
                showPassword={showPassword}
                handleShowPassword={handleShowPassword}
              />
            </div>
          </div>
          <div className="mt-6">
            <button
              type="submit"
              className="block w-full rounded-md bg-teal-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            >
              Inicia sesión
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
