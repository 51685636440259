import React, { useState, useEffect } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Toolbar, Inject } from '@syncfusion/ej2-react-grids';

import { contextMenuItems, ordersData as initialData, patientsGrid } from '../data/dummy';
import { Header } from '../components';
import { getAllPatients } from '../actions/patients';

const PatientList = () => {
  const editing = { allowDeleting: false, allowEditing: false };

  const [data, setData] = useState([]);
  let gridInstance = null;

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const response = await getAllPatients();

        setData(response);
      } catch (error) {
        console.error("Error al obtener los pacientes:", error);
      }
    };

    fetchPatients();
  }, []);


  const toolbarClick = (args) => {
    if (gridInstance) {
      if (args.item.id === 'gridcomp_excelexport') {
        gridInstance.excelExport();
      } else if (args.item.id === 'gridcomp_pdfexport') {
        gridInstance.pdfExport();
      }
    }
  };

  const pageSettings = { pageSize: 50 };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Pacientes" title="Lista de contactos por directorio web" />
      <GridComponent
        id="gridcomp"
        ref={(grid) => (gridInstance = grid)}
        dataSource={data}
        allowPaging
        allowSorting
        allowExcelExport
        allowPdfExport
        contextMenuItems={contextMenuItems}
        editSettings={editing}
        toolbar={['ExcelExport', 'PdfExport']}
        toolbarClick={toolbarClick}
        pageSettings={pageSettings}
      >
        <ColumnsDirective>
          {patientsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport, Toolbar]} />
      </GridComponent>
    </div>
  );
};

export default PatientList;
