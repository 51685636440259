import React from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";

const Input = ({ name, type, label, handleChange, handleShowPassword, showPassword }) => {
  return (
    <div className="relative">
      <label
        htmlFor={name}
        className="block text-sm font-semibold leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="mt-1">
        <input
          type={type}
          name={name}
          id={name}
          autoComplete={name}
          onChange={handleChange}
          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-400 sm:text-sm sm:leading-6"
        />
        {name === "password" ? (
          <span
            onClick={handleShowPassword}
            className="absolute inset-y-0 top-7 right-3 flex items-center cursor-pointer"
          >
            {showPassword ? (
              <FiEyeOff className="text-gray-500" />
            ) : (
              <FiEye className="text-gray-500" />
            )}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default Input;
