import axios from "axios";

const API = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });

API.interceptors.request.use((req) => {
    const profile = localStorage.getItem("profile");

    if (profile) {
        req.headers.Authorization = `Bearer ${JSON.parse(profile).token}`;
    }

    return req;
}, (error) => {
    return Promise.reject(error);
});

export default API;